.resume-intro-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 40px;
    text-align: center;
}

.resume-button-container {
    text-align: center;
    margin: 0 15vw;
    margin-top: 20px;
}
