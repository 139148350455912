.overview-container {
    text-align: center;
    margin: 0 15vw;
    margin-top: 50px;
}

.image-container {
    flex: 34%;
}

.content-container {
    margin: 20px 15vw;
    display: flex;
}

.text-container {
    flex: 66%;
    text-align: justify;
    margin: 20px 5px;
}

.overview-image {
    max-height: 500px;
    justify-content: left;
}

.overview-text-element {
    margin-bottom: 20px;
    font-size: large;
}

.overview-button-container {
    text-align: center;
    margin: 0 15vw;
    margin-bottom: 50px;
}

.image-reference {
    display: block;
}

/* responsiveness */
@media only screen and (max-width: 480px) {
    .image-container,
    .text-container {
        width: 100%;
        flex: 100%;
        text-align: center;
    }
    .content-container {
        display: block;
        text-align: center;
    }
    .overview-image {
        max-height: 200px;
        justify-content: center;
    }
}

@media screen and (max-width: 480px) {
    .image-container {
        width: 100%;
        flex: 100%;
        text-align: center;
    }
    .text-container {
        width: 100%;
        flex: 100%;
        padding: 0px 10vw;
        text-align: center;
    }
    .content-container {
        display: block;
        margin: 20px 3vw;
        text-align: center;
    }
    .overview-image {
        height: 400px;
        justify-content: center;
    }
}

@media screen and (max-width: 900px) {
    .image-container {
        width: 100%;
        flex: 100%;
        text-align: center;
    }
    .text-container {
        width: 100%;
        flex: 100%;
        padding: 0px 10vw;
        text-align: center;
    }
    .content-container {
        display: block;
        text-align: center;
    }
    .overview-image {
        max-height: 400px;
        justify-content: center;
    }
}

@media screen and (max-width: 1500px) {
    .image-container {
        flex: 34%;
    }
    .text-container {
        flex: 66%;
        text-align: justify;
        margin: 10px 5px;
        padding-left: 25px;
    }
    .overview-image {
        max-height: 500px;
        justify-content: left;
    }
}
