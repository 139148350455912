.experience-stack {
    display: inline-block;
    margin-right: 20px;
    margin-top: 10px;
}

.resume-experience {
    line-height: 1.5;
    margin-bottom: 25px;
}

/* responsiveness */
@media screen and (max-width: 600px) {
    .resume-experience {
        text-align: justify;
    }
}
