.resume-projects {
    margin: 25px 0px;
}

.project-stack {
    display: inline;
    margin-right: 15px;
}

.tech-stack {
    margin-top: 5px;
    line-height: 0.5;
}

/* responsiveness */
@media screen and (max-width: 600px) {
    .resume-projects {
        text-align: justify;
    }
}
