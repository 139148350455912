* {
    font-family: "Recursive", Arial, "Helvetica Neue", Helvetica, sans-serif;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.nav-items *:hover {
    transform: scale(1.05);
}

.profile-img {
    width: 30px;
    margin: 0px 20px;
    background-size: cover;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

/* responsiveness */
@media screen and (max-width: 600px) {
    div h1 {
        font-size: 1.87em;
    }
    div h2 {
        font-size: 1.75em;
    }
    div h3 {
        font-size: 1.5em;
    }
    div h4 {
        font-size: 1.17em;
    }
    div h5 {
        font-size: 1em;
    }
    div h6 {
        font-size: 0.87em;
    }
}
