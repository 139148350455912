.contact-body {
    height: 15vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    text-align: center;
}

.contact-body-form-container {
    padding: 20px 20vw 0px 20vw;
    color: white;
}

/* responsiveness */
@media screen and (max-width: 600px) {
    .contact-body {
        height: 30vh;
    }
    .contact-body-form-container {
        padding: 20px 10vw 0px 10vw;
    }
}
