/* the content part */
.long-left {
    flex: 65%;
    margin: 0px 0px 0px 40px;
    color: white;
    text-align: justify;
}

/* the content part */
.long-right {
    flex: 65%;
    margin: 0px 40px 0px 0px;
    color: white;
    text-align: justify;
}

/* the image part */
.short {
    flex: 35%;
}

/* the project card */
.project-container {
    padding: 50px 10vw 50px 10vw;
    border-radius: 12px;
    display: flex;
}

/* project image */

.project-image-left {
    border-radius: 12px 12px 12px 12px;
    max-width: 25vw;
}

/* project image */

.project-image-right {
    border-radius: 12px 12px 12px 12px;
    max-width: 25vw;
}

/* time period */
.period {
    font-size: 14px;
}

/* responsiveness */
@media screen and (max-width: 960px) {
    .long {
        width: 100%;
        flex: 100%;
        text-align: center;
        padding: 20px 10px 10px 10px;
        margin: 0px;
    }
    .short {
        width: 100%;
        flex: 100%;
        text-align: center;
        margin: 0px;
        max-width: 100vw;
    }
    .project-container {
        display: block;
        text-align: center;
        padding: 20px 5vw 20px 5vw;
    }
    .project-image-right {
        border-radius: 12px 12px 12px 12px;
        max-width: 80vw;
    }
    .project-image-left {
        border-radius: 12px 12px 12px 12px;
        max-width: 80vw;
    }
}
