.course-major {
    margin-top: 5px;
}

.education {
    padding-bottom: 30px;
}

/* responsiveness */
@media screen and (max-width: 1024px) {
    .education {
        text-align: center;
        padding-bottom: 10px;
    }
}
