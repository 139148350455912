.project-image {
    width: 100vw;
    height: 45vh;
    position: relative;
}

.header-container {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: absolute;
    margin-bottom: 50px;
}

.header-title {
    z-index: 10;
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: auto;
    position: absolute;
    text-align: center;
    background: rgba(22, 35, 51, 0.4);
}

.header-title-lite {
    z-index: 10;
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: auto;
    position: absolute;
    text-align: center;
    background: rgba(22, 35, 51, 0);
}

.header-title-text {
    font-size: 100px;
}

/* responsiveness */
@media screen and (max-width: 1024px) {
    .header-title-text {
        font-size: 50px;
    }
}

@media screen and (max-width: 700px) {
    .header-title-text {
        font-size: 80px;
    }
}

@media screen and (max-width: 1024px) {
    .header-title-text {
        font-size: 60px;
    }
}
