.resume-body {
    text-align: center;
    margin: 0 15vw;
    margin-top: 50px;
}

.resume-content {
    display: flex;
}

.resume-content-heading {
    flex: 30%;
    text-align: left;
}

.resume-content-data {
    flex: 70%;
    text-align: justify;
    margin: 20px 5px;
}

.resume-projects-button-container {
    text-align: center;
    margin: 0px 15vw 30px 15vw;
}

/* responsiveness */
@media screen and (max-width: 1024px) {
    .resume-content-heading {
        width: 100%;
        flex: 100%;
        text-align: center;
    }
    .resume-content-data {
        width: 100%;
        flex: 100%;
        margin: 0px 5px 15px 5px;
        text-align: center;
    }
    .resume-content {
        display: block;
        text-align: center;
    }
}
