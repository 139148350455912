.projects-section {
    width: 100%;
}

.projects-overview-title {
    text-align: center;
    margin: 0 15vw;
    color: white;
    margin: 0px;
    padding-top: 50px;
}

.more-projects {
    text-align: center;
    margin: 0 15vw;
    padding-bottom: 50px;
}

.projects-overview-card-deck {
    margin: 30px 15vw;
    justify-content: center;
}

.project-overview-card-text {
    text-align: justify;
}

.project-overview-card-body {
    justify-content: center;
    align-items: center;
    grid-template-rows: auto;
}
